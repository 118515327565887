<template>
  <div class="base-header-reservation-request-count" @click="handleClick">
    <div class="text">予約申請</div>
    <div class="count" v-if="unappliedCounts">{{ unappliedCounts }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseHeaderReservationRequestCount',
  props: {
    unappliedCounts: {
      type: [Number, String]
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-header-reservation-request-count {
  margin-top: 16px;
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .header-logo-img {
    float: left;
    width: 20px;
    height: 22px;
  }
  .text {
    float: left;
  }
  .count {
    float: left;
    margin-left: 15px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 5px;
    color: #{$white};
    font-size: 14px;
    background-color: #{$tomato};
  }
}
@media (max-width: $horizontal-tablet-width) {
  .base-header-reservation-request-count {
    .text {
      font-size: 13px;
    }
  }
}
</style>
