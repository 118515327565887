import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonMutations,
  commonActions
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'examinationFormats'
const keys = ['treatmentOriginalId']

const state = {
  examinationFormats: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  )
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { examinationFormat, examinationTopicClasses }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/examination-formats', {
        examinationFormat,
        examinationTopicClasses
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.examinationFormat])
        commit(
          'examinationTopicClasses/updateAfter',
          res.data.examinationTopicClasses,
          { root: true }
        )
        commit('examinationTopics/updateAfter', res.data.examinationTopics, {
          root: true
        })
        commit('examinationSpecies/updateAfter', res.data.examinationSpecies, {
          root: true
        })
        return 'success'
      }
    } catch (error) {
      let result = 'server error'
      if (error.response?.data?.message) {
        const { message, extra } = error.response.data
        if (message === 'no data') {
          result = `no ${extra}`
        } else if (message === 'invalid data') {
          result = extra
        } else {
          result = message
        }
      }
      return result
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/examination-formats', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.examinationFormat])
        commit(
          'examinationTopicClasses/updateAfter',
          res.data.examinationTopicClasses,
          { root: true }
        )
        commit('examinationTopics/updateAfter', res.data.examinationTopics, {
          root: true
        })
        commit('examinationSpecies/updateAfter', res.data.examinationSpecies, {
          root: true
        })
        return true
      }
    } catch (error) {
      if (error.response?.data?.extra?.data) {
        commit('setData', error.response.data.extra.data)
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, { examinationFormat, examinationTopicClasses }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/examination-formats', {
        examinationFormat,
        examinationTopicClasses
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.examinationFormats)
        commit(
          'examinationTopicClasses/updateAfter',
          res.data.examinationTopicClasses,
          { root: true }
        )
        commit('examinationTopics/updateAfter', res.data.examinationTopics, {
          root: true
        })
        commit('examinationSpecies/updateAfter', res.data.examinationSpecies, {
          root: true
        })
        return 'success'
      }
    } catch (error) {
      let result = 'server error'
      if (error.response?.data?.message) {
        const { message, extra } = error.response.data
        if (message === 'no data') {
          result = `no ${extra}`
        } else if (message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          result = 'no examinationFormat'
        } else {
          result = message
        }
      }
      return result
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
