import { makePriceDetail } from '@/utils/price_calculation'
import { dateDiff } from '@/utils/date'
import { makeClinicText, makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts.js'
import { removeWeekday } from '@/utils/convert_string.js'
import _ from 'lodash'
import JsBarcode from 'jsbarcode'
import { makeOwnerPatientText } from './print_utils'

const makeTreatmentRow = treatmentItem => {
  const subtotal = [
    { text: `¥${treatmentItem.subtotal.toLocaleString()}`, style: 'cellRight' }
  ]
  if (treatmentItem.discountRate !== 0) {
    subtotal.push({
      text: `割引 ${treatmentItem.discountRate}%`,
      style: 'tinyRight'
    })
  }
  if (treatmentItem.discountPrice !== 0) {
    subtotal.push({
      text: `値引 ¥${treatmentItem.discountPrice.toLocaleString()}`,
      style: 'tinyRight'
    })
  }
  return [
    {
      text: `${treatmentItem.name}${
        treatmentItem.taxExemptFlg ? '（非課税）' : ''
      }`,
      style: 'cellLeft'
    },
    { text: treatmentItem.insuranceFlg ? '〇' : '', style: 'cellCenter' },
    {
      text: `¥${treatmentItem.unitPrice.toLocaleString()}`,
      style: 'cellRight'
    },
    { text: treatmentItem.amount, style: 'cellRight' },
    subtotal
  ]
}

const makeTreatmentRows = medicalPayment => {
  if (
    medicalPayment.middleCalculateFlg ||
    (medicalPayment.endHospitalizationFlg &&
      !medicalPayment.startHospitalizationFlg)
  ) {
    return medicalPayment.treatmentItemsAndTreatmentDates.map(v => {
      if (v.hasOwnProperty('treatmentDate')) {
        return [
          { text: v.treatmentDate, style: 'cellLeft' },
          { text: '', style: 'cellCenter' },
          { text: '', style: 'cellRight' },
          { text: '', style: 'cellRight' },
          [{ text: '', style: 'cellRight' }]
        ]
      } else {
        return makeTreatmentRow(v)
      }
    })
  } else {
    return medicalPayment.treatmentItems.map(v => {
      return makeTreatmentRow(v)
    })
  }
}

// makeDocDefinition で使用される
// 金額表の作成
export const makePriceDetailRows = (
  medicalPayment,
  ratio,
  showBarcode,
  barcodePrefix
) => {
  let priceDetail
  if (
    medicalPayment.middleCalculateFlg ||
    (medicalPayment.endHospitalizationFlg &&
      !medicalPayment.startHospitalizationFlg)
  ) {
    const surgeryCount = medicalPayment.hospitalizationMedicalPayments.filter(
      v => v.surgeryFlg
    ).length
    //※↓2023/03/01 現状途中精算をした場合、途中精算と退院の診療明細で保険適用はできない仕様にしており、
    //  hStartDateとhEndDateを変えても金額は変わらないので、一律で入院開始日・退院日にしています
    const hStartDate = medicalPayment.hospitalization.startDate
    const hEndDate = medicalPayment.hospitalization.endDate
    const hDays = dateDiff(hStartDate, hEndDate, 'days') + 1
    priceDetail = makePriceDetail(
      medicalPayment,
      surgeryCount,
      hDays,
      medicalPayment.treatmentItems
    )
  } else {
    priceDetail = makePriceDetail(
      medicalPayment,
      medicalPayment.surgeryFlg, //← 0 or 1
      1,
      medicalPayment.treatmentItems
    )
  }
  const cellBlank = { text: '' }
  const baseStyle = { alignment: 'right' }
  const emphasisStyle = { ...baseStyle, fontSize: 14 * ratio, color: 'black' }
  let rows = [
    [
      { text: '' },
      { text: '小計', ...baseStyle },
      { text: `¥${priceDetail.totalPrice}`, ...baseStyle }
    ]
  ]
  let columLength = 2
  if (priceDetail.discountRate !== '0') {
    columLength = 3
    if (medicalPayment.insuranceType === 'ipet-docomo') {
      rows.push([
        { text: `全体割引 ${priceDetail.discountRate}%`, ...baseStyle },
        cellBlank,
        cellBlank
      ])
    } else {
      rows.push([
        { text: `全体割引 ${priceDetail.discountRate}%`, ...baseStyle },
        { text: '割引適用額', ...baseStyle },
        { text: `¥${priceDetail.rateDiscountedPrice}`, ...baseStyle }
      ])
    }
  }
  if (priceDetail.discountPrice !== '0') {
    columLength = 3
    rows.push([
      { text: `全体値引 ¥${priceDetail.discountPrice}`, ...baseStyle },
      { text: '値引適用額', ...baseStyle },
      { text: `¥${priceDetail.fixedDiscountedPrice}`, ...baseStyle }
    ])
  }
  let cellBarcode = cellBlank
  if (showBarcode) {
    columLength = 3
    // 自己負担額が7桁を超える場合 -> 印刷オプションでバーコード表示を選択できないように事前に対処している
    const barcodeText =
      barcodePrefix + String(medicalPayment.burdenAmount).padStart(6, '0')
    cellBarcode = {
      image: textToBase64Barcode(barcodeText, {
        width: 3,
        fontSize: 28,
        background: rows.length % 2 === 0 ? '#ffffff' : '#f0f0f0'
      }),
      fit: [100, 100],
      alignment: 'center'
    }
  }
  rows = rows.concat([
    [
      cellBlank,
      { text: `消費税（10%）`, ...baseStyle },
      { text: `¥${priceDetail.consumptionTax}`, ...baseStyle }
    ],
    [
      cellBlank,
      { text: '合計', ...baseStyle },
      { text: `¥${priceDetail.taxIncludedPrice}`, ...baseStyle }
    ],
    [
      cellBlank,
      { text: '保険負担額', ...baseStyle },
      { text: `¥${priceDetail.insurancePrice}`, ...baseStyle }
    ],
    [
      { ...cellBarcode },
      { text: '自己負担額', ...emphasisStyle },
      { text: `¥${priceDetail.ownerBill}`, ...emphasisStyle }
    ]
  ])
  if (columLength === 2) {
    rows = rows.map(row => {
      const [, ...rest] = row
      return rest
    })
  }
  return { rows, columLength }
}

export const makeDocDefinition = ({
  printOptions,
  clinic,
  clinicImage,
  barcodePrefix = '000000',
  nextReservationInfo = null,
  printData
}) => {
  const { paperSize, showBarcode, ...showClinicItems } = printOptions
  const ratio = paperSize === 'A4' ? 1 : paperSize === 'B5' ? 0.84 : 0.71
  //↓テーブル内の診療項目内容のwidthは、A4の値を基準にratioで値を算出するとB5の時に紙からはみ出てしまうため下記の値を使用
  const treatmentItemNameWidth =
    paperSize === 'A4' ? 266 : paperSize === 'B5' ? 226 : 161

  // 各印刷内容の作成
  const content = []
  for (const { owner, patient, medicalPayment } of printData) {
    const ownerPatientText = makeOwnerPatientText({ owner, patient })
    const clinicText = makeClinicText({
      ratio,
      clinic,
      clinicImage,
      ...showClinicItems
    })
    const treatmentRows = makeTreatmentRows(medicalPayment)
    const { rows: priceDetailRows, columLength } = makePriceDetailRows(
      medicalPayment,
      ratio,
      showBarcode,
      barcodePrefix
    )

    const contentItemHeader = [
      {
        columns: [
          {
            stack: [
              {
                //右端の医院情報のどれかの文字数が長い時に「診療明細」の文字が改行されないようwidthを設定。
                //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
                columns: [
                  {
                    text: '診療明細',
                    fontSize: 40 * ratio,
                    width: 260 * ratio
                  }
                ]
              },
              ownerPatientText
            ],
            width: '*'
          },
          {
            stack: clinicText,
            width: 'auto',
            margin: [10, 0, 0, 0]
          }
        ],
        ...(content.length > 0 ? { pageBreak: 'before' } : {}) // 改ページ処理
      }
    ]

    const contentItemBody = [
      // 診断名
      { text: medicalPayment.diseaseName, margin: [0, 15, 0, 0] },
      // 発症日
      {
        text: `発症日 ${removeWeekday(medicalPayment.onsetDate)}`,
        margin: [0, 5, 0, 0]
      },
      // 診療日
      {
        text: `診療日 ${removeWeekday(medicalPayment.date)}\t担当者: ${
          medicalPayment.staffName
        }`,
        alignment: 'right',
        margin: [0, 10, 0, 0]
      },
      // 診療項目内容のテーブル
      {
        margin: [0, 5, 0, 0],
        layout: 'treatmentItemsTable',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [
            treatmentItemNameWidth,
            25 * ratio,
            84 * ratio,
            50 * ratio,
            84 * ratio
          ],
          body: [
            [
              { text: '診療項目内容', alignment: 'center' },
              { text: '保険', alignment: 'center' },
              { text: '単価 (¥)', alignment: 'center' },
              { text: '数量', alignment: 'center' },
              { text: '小計 (¥)', alignment: 'center' }
            ],
            ...treatmentRows
          ]
        }
      },
      // 金額表
      {
        margin: [0, 5, 0, 0],
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            layout: 'priceDetailTable',
            table: {
              widths:
                columLength === 2 ? ['auto', 'auto'] : ['auto', 'auto', 'auto'],
              dontBreakRows: true,
              headerRows: 0,
              body: priceDetailRows
            }
          }
        ]
      },
      // 次回予約
      ...(nextReservationInfo
        ? Object.keys(nextReservationInfo).length > 0
          ? [
              {
                stack: [
                  { text: nextReservationInfo.text, margin: [0, 15, 0, 0] },
                  {
                    text: nextReservationInfo.patientNames,
                    margin: [70 * ratio, 0, 0, 0]
                  }
                ],
                unbreakable: true
              }
            ]
          : [{ text: '次回の予約：未設定', margin: [0, 15, 0, 0] }]
        : [])
    ]

    const contentItem = contentItemHeader.concat(contentItemBody)
    content.push(contentItem)
  }

  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({
        currentPage,
        pageCount,
        fontSize: 11 * ratio
      }),
    styles: {
      cellLeft: { fontSize: 11 * ratio, alignment: 'left' },
      cellCenter: { fontSize: 11 * ratio, alignment: 'center' },
      cellRight: { fontSize: 11 * ratio, alignment: 'right' },
      tinyRight: { fontSize: 8 * ratio, alignment: 'right' }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12 * ratio,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20, 20, 20, 30 + 11 * ratio],
    pageOrientation: 'portrait'
  }
}

// args = {
//   printOptions,
//   clinic,
//   clinicImage,
//   barcodePrefix,
//   nextReservationInfo,
//   printData
// }
export const printMedicalPayment = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    treatmentItemsTable: {
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: rowIndex => (rowIndex === 0 ? '#f0f0f0' : null)
    },
    priceDetailTable: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      paddingLeft: i => (i === 0 ? 5 : 20),
      fillColor: rowIndex => (rowIndex % 2 === 0 ? '#f0f0f0' : null)
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}

export const textToBase64Barcode = (text, options = {}) => {
  const canvas = document.createElement('canvas')
  JsBarcode(canvas, text, {
    format: 'EAN13',
    font: 'OCRB',
    ...options
  })
  const dataURL = canvas.toDataURL()
  return dataURL
}
